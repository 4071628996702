<template>
  <div>
    <b-spinner v-if="loadingPage"></b-spinner>
    <div v-else class="container mt-4">
      <b-col>
        <b-row>
          <b-col class="d-flex align-items-center">
            <BackButton></BackButton>
            <h5 class="ml-2 mb-0">
              {{ translations.import_transactions_details.back_list }}
            </h5>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <b-card
              class="border-0"
              :header="translations.import_transactions_details.page_title"
              body-class="px-0"
              header-class="border-0">
              <div class="col-12">
                <div class="row mb-5">
                  <div class="col-3">
                    <b>{{ translations.import_transactions_details.import_id }}</b>
                    <p>{{ details.id }}</p>
                  </div>
                  <div class="col-3">
                    <b>{{ translations.import_transactions_details.created_at }}</b>
                    <p>{{ formatDatetime(details.created_at) }}</p>
                  </div>
                  <div class="col-3">
                    <b>{{ translations.import_transactions_details.file_name }}</b>
                    <p>{{ details.original_filename }}</p>
                  </div>
                  <div class="col-3">
                    <b>{{ translations.import_transactions_details.created_by }}</b>
                    <p>{{ details.created_by }}</p>
                  </div>
                </div>
                <div class="row mb-5">
                  <div class="col-3">
                    <b>{{ translations.import_transactions_details.status }}</b>
                    <p class="text-capitalize">
                      <b-badge :variant="formatStatusLabel(details.status)">
                        <h6 class="badge-status mb-0">{{ details.status }}</h6>
                      </b-badge>
                    </p>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="d-flex justify-content-center align-items-start">
        <b-card
          class="border-0 mb-2 mt-3 flex-fill"
          body-class="px-0"
          header-class="border-0">
          <financial-list
            v-model="currentPage"
            :header-data="TABLE_HEADERS"
            :data="items"
            :disabled="loadingPage"
            :items-per-page="itemsPerPage"
            @page-changed="loadItems"
            :total="total"
            :no-items-message="translations.import_transactions_details.transaction_list.empty_list"/>
        </b-card>
      </b-col>
    </div>
  </div>
</template>

<script>
import translations from '@/translations';
import service from '@/services/finance-service';
import { parseResponseError } from '@/http/parsers/parse_response';
import BackButton from '@/components/BackButton.vue';
import FinancialList, { associateHeaderDataItem, generateCustomComponentColumn } from '@/components/Financial/FinancialList.vue';
import FinancialTextWithIcon from '@/components/Financial/TextWithIcon.vue';
import { formatDatetime } from '@/helpers/finance';

export default {
  name: 'Details',
  components: {
    BackButton,
    FinancialList,
  },
  created() {
    const t = this.translations.import_transactions_details.transaction_list.fields_pepm;
    this.TABLE_HEADERS = [
      associateHeaderDataItem(t.id, 'id_with_observations'),
      associateHeaderDataItem(t.client_id, 'client_id'),
      associateHeaderDataItem(t.billing_configuration_id, 'billing_configuration_id'),
      associateHeaderDataItem(t.quantity, 'quantity'),
      associateHeaderDataItem(t.unit_value, 'unit_value'),
      associateHeaderDataItem(t.transaction_date, 'transaction_date'),
      associateHeaderDataItem(t.original_transaction_pepm_id, 'original_transaction_pepm_id'),
      associateHeaderDataItem(t.status, 'formatted_status'),
      associateHeaderDataItem(t.created_transaction_id, 'created_transaction_id'),
      associateHeaderDataItem(t.created_transaction_value, 'created_transaction_value'),
      associateHeaderDataItem(t.created_transaction_value_type, 'created_transaction_value_type'),
    ];
  },
  data() {
    return {
      translations: translations.finance,
      importId: this.$route?.params?.importID ? Number(this.$route.params.importID) : 0,
      details: {},
      items: [],
      loadingPage: true,
      itemsPerPage: 10,
      currentPage: 1,
      total: 0,
    };
  },
  async beforeMount() {
    await this.loadItems();
  },
  methods: {
    formatDatetime,
    async changePage(page) {
      this.currentPage = page;
      await this.loadItems();
    },
    async loadItems() {
      this.loadingPage = true;
      try {
        const offset = (this.currentPage - 1) * this.itemsPerPage;
        const limit = this.itemsPerPage;

        this.details = await service.getManualTransactionPEPMDetails(this.importId, { limit, offset });
        this.total = this.details.items.total;
        this.items = this.details.items.data;
        this.items.forEach(item => {
          const propsData = { variant: this.formatStatusLabel(item.status) };
          item.formatted_status = generateCustomComponentColumn('BBadge', propsData, item.status);
          item.transaction_date = formatDatetime(item.transaction_date);

          const t = this.translations.import_transactions_details.transaction_list.fields_pepm;
          let tooltipText = `${t.tooltip_transaction_observations}: ${item.transaction_observations}`;
          if (item.observations) {
            tooltipText += `\n${t.tooltip_observations}: ${item.observations}`;
          }
          item.id_with_observations = generateCustomComponentColumn(FinancialTextWithIcon, {
            id: `${item.id}-observations`,
            tooltipText,
            text: item.id,
          }, item.id);
        });
      } catch (err) {
        this.$noty.error(`${this.translations.import_transactions_details.results.error_loading_list} ${parseResponseError(err)}`);
      } finally {
        this.loadingPage = false;
      }
    },
    formatStatusLabel(label) {
      if (this.isCompletedStatus(label)) return 'success';
      if (this.isFailedStatus(label)) return 'danger';
      return 'secondary';
    },
    isCompletedStatus(status) {
      if (!status) return false;
      return status.toLowerCase() === 'completed';
    },
    isFailedStatus(status) {
      if (!status) return false;
      return status.toLowerCase() === 'failed';
    },
  },
};
</script>
